

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme

// components
import { QueryClientProvider, QueryClient, } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
// auth

// ----------------------------------------------------------------------

const queryClient = new QueryClient();

export default function App() {


  return (
    <QueryClientProvider client={queryClient}>
          <Router />
          {process.env.REACT_APP_DEBUG_QUERY === 'true' && (
            <ReactQueryDevtools />
          )}
    </QueryClientProvider>
  );
}
