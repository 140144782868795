// @mui
import Box from '@mui/material/Box';
// auth
// routes
// hooks
// theme
// components
import { Grid } from '@mui/material';


import './style.module.css';
// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {


  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          backgroundColor: 'rgb(235, 242, 247)',
          zIndex: -1,
        }}
      />
      <Grid container sx={{ pt: '5vh', zIndex: 100, }}>
        <Grid item md={2} xs={12} />
        <Grid item md={8} xs={12}>
          <Box
            display="flex"
            textAlign="center"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            width="100%"
            padding={4}
          >
            {children}
          </Box>
        </Grid>
        <Grid item md={2} xs={12} />
      </Grid >
    </>
  );
}
