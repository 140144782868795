import { Navigate, useRoutes } from 'react-router-dom';
//
import { mainRoutes } from './main';




// ----------------------------------------------------------------------

export default function Router() {

  const routes = [
    ...mainRoutes,
    { path: '*', element: <Navigate to="/404" replace /> },
  ];


  return useRoutes(routes);
}
