import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';


const Root = lazy(() => import('src/pages/root'))

// ----------------------------------------------------------------------

const core = {
  element: (
      <AuthClassicLayout>
        <Outlet />
      </AuthClassicLayout>
  ),
  children: [
    { path: '', element: <Root /> },
  ],
};

export const mainRoutes = [
  core,
]